html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

input:-webkit-autofill:hover {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0 30px #fff !important;
}

:root {
  --white-web-space: #f4f4f4;
  --violet-dark-web-space: #512bd9;
  --violet-light-web-space: #8f49f2;
  --violet-dark-gradient-opacity10-web-space-t: #8f49f21a;
  --violet-dark-gradient-opacity10-web-space-b: #512bd91a;
  --violet-dark-gradient-opacity10-web-space: linear-gradient(to bottom, var(--violet-dark-gradient-opacity10-web-space-t) 30%, var(--violet-dark-gradient-opacity10-web-space-b) 70%);
  --violet-light-1: #8f49f2;
  --violet-light-2: #512bd9;
  --violet-light-gradient: linear-gradient(180deg, var(--violet-light-1) 0%, var(--violet-light-2) 100%);
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Bold Cnd;
  src: local(Akzidenz-Grotesk Pro BoldCndIt), local(AkzidenzGroteskPro-BoldCnIt), url("AkzidenzGroteskPro-BoldCnIt.bc7da100.woff2") format("woff2"), url("AkzidenzGroteskPro-BoldCnIt.601e81b8.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Bold Ext;
  src: local(Akzidenz-Grotesk Pro BoldExtIt), local(AkzidenzGroteskPro-BoldExIt), url("AkzidenzGroteskPro-BoldExIt.b110b897.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-BoldExIt.38e50f9f.woff2") format("woff2"), url("AkzidenzGroteskPro-BoldExIt.8ebf2321.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Cnd;
  src: local(Akzidenz-Grotesk Pro Cnd), local(AkzidenzGroteskPro-Cn), url("AkzidenzGroteskPro-Cn.2964e92b.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-Cn.3d88b44d.woff2") format("woff2"), url("AkzidenzGroteskPro-Cn.143f9237.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro;
  src: local(Akzidenz-Grotesk Pro Bold), local(AkzidenzGroteskPro-Bold), url("AkzidenzGroteskPro-Bold.34fef30e.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-Bold.5a5f394c.woff2") format("woff2"), url("AkzidenzGroteskPro-Bold.5c00485c.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Light Cnd;
  src: local(Akzidenz-Grotesk Pro LightCndIt), local(AkzidenzGroteskPro-LightCnItalic), url("AkzidenzGroteskPro-LightCnItalic.e641f03f.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-LightCnItalic.f5639603.woff2") format("woff2"), url("AkzidenzGroteskPro-LightCnItalic.d982a5f9.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Light Ext;
  src: local(Akzidenz-Grotesk Pro Light Ext), local(AkzidenzGroteskPro-LightEx), url("AkzidenzGroteskPro-LightEx.194ec6d6.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-LightEx.d018c4f5.woff2") format("woff2"), url("AkzidenzGroteskPro-LightEx.1da2264b.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Med Ext;
  src: local(Akzidenz-Grotesk Pro Med Ext), local(AkzidenzGroteskPro-MdEx), url("AkzidenzGroteskPro-MdEx.362efb09.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-MdEx.bd5731ba.woff2") format("woff2"), url("AkzidenzGroteskPro-MdEx.988f1cbf.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Cnd;
  src: local(Akzidenz-Grotesk Pro CndIt), local(AkzidenzGroteskPro-CnIt), url("AkzidenzGroteskPro-CnIt.14c3fb29.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-CnIt.8417b77c.woff2") format("woff2"), url("AkzidenzGroteskPro-CnIt.662bd18a.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Light;
  src: local(Akzidenz-Grotesk Pro Light), local(AkzidenzGroteskPro-Light), url("AkzidenzGroteskPro-Light.f1209895.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-Light.3a589fdf.woff2") format("woff2"), url("AkzidenzGroteskPro-Light.41383589.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Bold Cnd;
  src: local(Akzidenz-Grotesk Pro Bold Cnd), local(AkzidenzGroteskPro-BoldCn), url("AkzidenzGroteskPro-BoldCn.49a9e5c0.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-BoldCn.45f6cfad.woff2") format("woff2"), url("AkzidenzGroteskPro-BoldCn.bac0b3fa.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro XBd Cnd;
  src: local(Akzidenz-Grotesk Pro XBdCndIt), local(AkzidenzGroteskPro-XBdCnIt), url("AkzidenzGroteskPro-XBdCnIt.e82d3972.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-XBdCnIt.044f868b.woff2") format("woff2"), url("AkzidenzGroteskPro-XBdCnIt.5b383eab.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Super;
  src: local(Akzidenz-Grotesk Pro Super), local(AkzidenzGroteskPro-Super), url("AkzidenzGroteskPro-Super.33c0e967.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-Super.5022b73f.woff2") format("woff2"), url("AkzidenzGroteskPro-Super.95075a80.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Med;
  src: local(Akzidenz-Grotesk Pro Med), local(AkzidenzGroteskPro-Md), url("AkzidenzGroteskPro-Md.9944184b.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-Md.cf8be701.woff2") format("woff2"), url("AkzidenzGroteskPro-Md.1fd02f8b.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Ext;
  src: local(Akzidenz-Grotesk Pro ExtItalic), local(AkzidenzGroteskPro-ExtItalic), url("AkzidenzGroteskPro-ExtItalic.4169c506.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-ExtItalic.4a9be6a2.woff2") format("woff2"), url("AkzidenzGroteskPro-ExtItalic.c562f9e0.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Med;
  src: local(Akzidenz-Grotesk Pro MedItal), local(AkzidenzGroteskPro-MdIt), url("AkzidenzGroteskPro-MdIt.7a1e76c0.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-MdIt.c719c040.woff2") format("woff2"), url("AkzidenzGroteskPro-MdIt.8d45fd60.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro;
  src: local(Akzidenz-Grotesk Pro Italic), local(AkzidenzGroteskPro-It), url("AkzidenzGroteskPro-It.a06ae1cb.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-It.23d2a8e4.woff2") format("woff2"), url("AkzidenzGroteskPro-It.ac226040.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro;
  src: local(Akzidenz-Grotesk Pro ExtraBldIt), local(AkzidenzGroteskPro-XBdIt), url("AkzidenzGroteskPro-XBdIt.fbd5f594.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-XBdIt.a976319b.woff2") format("woff2"), url("AkzidenzGroteskPro-XBdIt.6efc1805.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Bold Ext;
  src: local(Akzidenz-Grotesk Pro Bold Ext), local(AkzidenzGroteskPro-BoldEx), url("AkzidenzGroteskPro-BoldEx.2a0201da.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-BoldEx.460d91c3.woff2") format("woff2"), url("AkzidenzGroteskPro-BoldEx.1ea53321.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Med Cnd;
  src: local(Akzidenz-Grotesk Pro MedCndIt), local(AkzidenzGroteskPro-MdCnIt), url("AkzidenzGroteskPro-MdCnIt.cd374558.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-MdCnIt.d667e2d3.woff2") format("woff2"), url("AkzidenzGroteskPro-MdCnIt.5745679d.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro;
  src: local(Akzidenz-Grotesk Pro BoldIt), local(AkzidenzGroteskPro-BoldIt), url("AkzidenzGroteskPro-BoldIt.7e660bb2.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-BoldIt.0648246d.woff2") format("woff2"), url("AkzidenzGroteskPro-BoldIt.3e92eee6.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro;
  src: local(Akzidenz-Grotesk Pro ExtraBold), local(AkzidenzGroteskPro-XBd), url("AkzidenzGroteskPro-XBd.f00dc15d.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-XBd.ccbf24f1.woff2") format("woff2"), url("AkzidenzGroteskPro-XBd.156e6127.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Light Cnd;
  src: local(Akzidenz-Grotesk Pro Light Cnd), local(AkzidenzGroteskPro-LightCn), url("AkzidenzGroteskPro-LightCn.c0a7223d.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-LightCn.8b104a20.woff2") format("woff2"), url("AkzidenzGroteskPro-LightCn.37c35dca.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Med Ext;
  src: local(Akzidenz-Grotesk Pro MedExtIt), local(AkzidenzGroteskPro-MedExtIt), url("AkzidenzGroteskPro-MedExtIt.9240acb8.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-MedExtIt.b8560688.woff2") format("woff2"), url("AkzidenzGroteskPro-MedExtIt.f70e3442.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Super;
  src: local(Akzidenz-Grotesk Pro SuperIt), local(AkzidenzGroteskPro-SuperItalic), url("AkzidenzGroteskPro-SuperItalic.a3b80f9a.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-SuperItalic.c5e3448b.woff2") format("woff2"), url("AkzidenzGroteskPro-SuperItalic.4681cdc5.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Ext;
  src: local(Akzidenz-Grotesk Pro Ext), local(AkzidenzGroteskPro-Ext), url("AkzidenzGroteskPro-Ext.be113890.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-Ext.d742aec1.woff2") format("woff2"), url("AkzidenzGroteskPro-Ext.e2b438c8.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro XBd Cnd;
  src: local(Akzidenz-Grotesk Pro XBd Cnd), local(AkzidenzGroteskPro-XBdCn), url("AkzidenzGroteskPro-XBdCn.b7d02810.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-XBdCn.a5506b7c.woff2") format("woff2"), url("AkzidenzGroteskPro-XBdCn.c2d98cf5.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Regular;
  src: local(Akzidenz-Grotesk Pro Regular), local(AkzidenzGroteskPro-Regular), url("AkzidenzGroteskPro-Regular.c6797a5f.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-Regular.6970bec0.woff2") format("woff2"), url("AkzidenzGroteskPro-Regular.6ba62d65.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Med Cnd;
  src: local(Akzidenz-Grotesk Pro Med Cnd), local(AkzidenzGroteskPro-MdCn), url("AkzidenzGroteskPro-MdCn.52dcdaa1.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-MdCn.41309d3d.woff2") format("woff2"), url("AkzidenzGroteskPro-MdCn.879ab210.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro;
  src: local(Akzidenz-Grotesk Pro LightIt), local(AkzidenzGroteskPro-LightIt), url("AkzidenzGroteskPro-LightIt.d681bc3a.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-LightIt.9e6980d3.woff2") format("woff2"), url("AkzidenzGroteskPro-LightIt.24d5f41b.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Akzidenz-Grotesk Pro Light Ext;
  src: local(Akzidenz-Grotesk Pro LightExtIt), local(AkzidenzGroteskPro-LigExtIt), url("AkzidenzGroteskPro-LigExtIt.a83fbb8d.eot#iefix") format("embedded-opentype"), url("AkzidenzGroteskPro-LigExtIt.339b7baa.woff2") format("woff2"), url("AkzidenzGroteskPro-LigExtIt.cdcf9c33.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

:root {
  --fs-m: 1.6rem;
  font-family: Akzidenz-Grotesk Pro Light Ext, sans-serif;
}

:root {
  font-size: 62.5%;
  overflow: hidden scroll;
}

@media (width >= 500px) {
  :root {
    font-size: 76.59%;
  }
}

@media (width >= 600px) {
  :root {
    font-size: 91.91%;
  }
}

@media (width >= 700px) {
  :root {
    font-size: 107.23%;
  }
}

@media (width >= 800px) {
  :root {
    font-size: 122.54%;
  }
}

@media (width >= 900px) {
  :root {
    font-size: 137.86%;
  }
}

html {
  scroll-behavior: smooth;
}

main {
  max-width: 1920px;
  margin: auto;
}

* {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}

.video {
  border-radius: 40px;
  max-width: 100%;
}

.image--rounded {
  border-radius: 50px;
}

.whatsappFloating {
  z-index: 1;
  width: 7rem;
  position: fixed;
  bottom: 1.6rem;
  right: 1.6rem;
}

.grecaptcha-badge {
  display: none;
}

@media (width >= 1024px) {
  :root {
    font-size: 33.33%;
  }

  .whatsappFloating {
    width: 8rem;
    bottom: 7.5rem;
    right: 7.5rem;
  }
}

@media (width >= 1100px) {
  :root {
    font-size: 35.81%;
  }
}

@media (width >= 1200px) {
  :root {
    font-size: 39.06%;
  }
}

@media (width >= 1280px) {
  :root {
    font-size: 41.66%;
  }
}

@media (width >= 1300px) {
  :root {
    font-size: 42.32%;
  }
}

@media (width >= 1400px) {
  :root {
    font-size: 45.57%;
  }
}

@media (width >= 1500px) {
  :root {
    font-size: 48.83%;
  }
}

@media (width >= 1600px) {
  :root {
    font-size: 52.08%;
  }
}

@media (width >= 1700px) {
  :root {
    font-size: 55.34%;
  }
}

@media (width >= 1800px) {
  :root {
    font-size: 58.59%;
  }
}

@media (width >= 1920px) {
  :root {
    font-size: 62.5%;
  }

  .video {
    width: auto;
  }
}

@media (width >= 1921px) {
  body {
    background-color: var(--white-web-space);
  }

  main {
    background-color: #fff;
    margin-top: 8rem;
  }
}
/*# sourceMappingURL=index.8ebfce69.css.map */
