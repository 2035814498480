@font-face {
	font-family: "Akzidenz-Grotesk Pro Bold Cnd";
	src: local("Akzidenz-Grotesk Pro BoldCndIt"),
		local("AkzidenzGroteskPro-BoldCnIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldCnIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldCnIt.woff")
			format("woff");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Bold Ext";
	src: local("Akzidenz-Grotesk Pro BoldExtIt"),
		local("AkzidenzGroteskPro-BoldExIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldExIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldExIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldExIt.woff")
			format("woff");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Cnd";
	src: local("Akzidenz-Grotesk Pro Cnd"), local("AkzidenzGroteskPro-Cn"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Cn.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Cn.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Cn.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro";
	src: local("Akzidenz-Grotesk Pro Bold"), local("AkzidenzGroteskPro-Bold"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Bold.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Bold.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Bold.woff")
			format("woff");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Light Cnd";
	src: local("Akzidenz-Grotesk Pro LightCndIt"),
		local("AkzidenzGroteskPro-LightCnItalic"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightCnItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightCnItalic.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightCnItalic.woff")
			format("woff");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Light Ext";
	src: local("Akzidenz-Grotesk Pro Light Ext"),
		local("AkzidenzGroteskPro-LightEx"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightEx.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightEx.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightEx.woff")
			format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Med Ext";
	src: local("Akzidenz-Grotesk Pro Med Ext"), local("AkzidenzGroteskPro-MdEx"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdEx.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdEx.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdEx.woff")
			format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Cnd";
	src: local("Akzidenz-Grotesk Pro CndIt"), local("AkzidenzGroteskPro-CnIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-CnIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-CnIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-CnIt.woff")
			format("woff");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Light";
	src: local("Akzidenz-Grotesk Pro Light"), local("AkzidenzGroteskPro-Light"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Light.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Light.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Light.woff")
			format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Bold Cnd";
	src: local("Akzidenz-Grotesk Pro Bold Cnd"),
		local("AkzidenzGroteskPro-BoldCn"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldCn.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldCn.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldCn.woff")
			format("woff");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro XBd Cnd";
	src: local("Akzidenz-Grotesk Pro XBdCndIt"),
		local("AkzidenzGroteskPro-XBdCnIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBdCnIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBdCnIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBdCnIt.woff")
			format("woff");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Super";
	src: local("Akzidenz-Grotesk Pro Super"), local("AkzidenzGroteskPro-Super"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Super.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Super.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Super.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Med";
	src: local("Akzidenz-Grotesk Pro Med"), local("AkzidenzGroteskPro-Md"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Md.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Md.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Md.woff")
			format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Ext";
	src: local("Akzidenz-Grotesk Pro ExtItalic"),
		local("AkzidenzGroteskPro-ExtItalic"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-ExtItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-ExtItalic.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-ExtItalic.woff")
			format("woff");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Med";
	src: local("Akzidenz-Grotesk Pro MedItal"), local("AkzidenzGroteskPro-MdIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdIt.woff")
			format("woff");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro";
	src: local("Akzidenz-Grotesk Pro Italic"), local("AkzidenzGroteskPro-It"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-It.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-It.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-It.woff")
			format("woff");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro";
	src: local("Akzidenz-Grotesk Pro ExtraBldIt"),
		local("AkzidenzGroteskPro-XBdIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBdIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBdIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBdIt.woff")
			format("woff");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Bold Ext";
	src: local("Akzidenz-Grotesk Pro Bold Ext"),
		local("AkzidenzGroteskPro-BoldEx"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldEx.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldEx.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldEx.woff")
			format("woff");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Med Cnd";
	src: local("Akzidenz-Grotesk Pro MedCndIt"),
		local("AkzidenzGroteskPro-MdCnIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdCnIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdCnIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdCnIt.woff")
			format("woff");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro";
	src: local("Akzidenz-Grotesk Pro BoldIt"), local("AkzidenzGroteskPro-BoldIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-BoldIt.woff")
			format("woff");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro";
	src: local("Akzidenz-Grotesk Pro ExtraBold"), local("AkzidenzGroteskPro-XBd"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBd.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBd.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBd.woff")
			format("woff");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Light Cnd";
	src: local("Akzidenz-Grotesk Pro Light Cnd"),
		local("AkzidenzGroteskPro-LightCn"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightCn.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightCn.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightCn.woff")
			format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Med Ext";
	src: local("Akzidenz-Grotesk Pro MedExtIt"),
		local("AkzidenzGroteskPro-MedExtIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MedExtIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MedExtIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MedExtIt.woff")
			format("woff");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Super";
	src: local("Akzidenz-Grotesk Pro SuperIt"),
		local("AkzidenzGroteskPro-SuperItalic"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-SuperItalic.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-SuperItalic.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-SuperItalic.woff")
			format("woff");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Ext";
	src: local("Akzidenz-Grotesk Pro Ext"), local("AkzidenzGroteskPro-Ext"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Ext.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Ext.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Ext.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro XBd Cnd";
	src: local("Akzidenz-Grotesk Pro XBd Cnd"), local("AkzidenzGroteskPro-XBdCn"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBdCn.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBdCn.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-XBdCn.woff")
			format("woff");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Regular";
	src: local("Akzidenz-Grotesk Pro Regular"),
		local("AkzidenzGroteskPro-Regular"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Regular.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-Regular.woff")
			format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Med Cnd";
	src: local("Akzidenz-Grotesk Pro Med Cnd"), local("AkzidenzGroteskPro-MdCn"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdCn.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdCn.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-MdCn.woff")
			format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro";
	src: local("Akzidenz-Grotesk Pro LightIt"),
		local("AkzidenzGroteskPro-LightIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LightIt.woff")
			format("woff");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Akzidenz-Grotesk Pro Light Ext";
	src: local("Akzidenz-Grotesk Pro LightExtIt"),
		local("AkzidenzGroteskPro-LigExtIt"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LigExtIt.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LigExtIt.woff2")
			format("woff2"),
		url("../assets/fonts/AkzindezGroteskPro/AkzidenzGroteskPro-LigExtIt.woff")
			format("woff");
	font-weight: 300;
	font-style: italic;
}

:root {
	font-family: "Akzidenz-Grotesk Pro Light Ext", sans-serif;
	--fs-m: 1.6rem;
}
