@import url("reset.css");
@import url("colors.css");
@import url("fonts.css");

:root {
	font-size: 62.5%;
	overflow-x: hidden;
	overflow-y: scroll;
}

@media (min-width: 500) {
	:root {
		font-size: 76.59%;
	}
}

@media (min-width: 600) {
	:root {
		font-size: 91.91%;
	}
}

@media (min-width: 700) {
	:root {
		font-size: 107.23%;
	}
}

@media (min-width: 800) {
	:root {
		font-size: 122.54%;
	}
}

@media (min-width: 900) {
	:root {
		font-size: 137.86%;
	}
}

html {
	scroll-behavior: smooth;
}

main {
	max-width: 1920px;
	margin: auto;
}

* {
	-webkit-tap-highlight-color: transparent;
}

img {
	max-width: 100%;
}

.video {
	border-radius: 40px;
	max-width: 100%;
}

.image--rounded {
	border-radius: 50px;
}

.whatsappFloating {
	position: fixed;
	bottom: 1.6rem;
	right: 1.6rem;
	width: 7rem;
	z-index: 1;
}

.grecaptcha-badge {
	display: none;
}

@media (min-width: 1024) {
	:root {
		font-size: 33.33%;
	}

	.whatsappFloating {
		width: 8rem;
		bottom: 7.5rem;
		right: 7.5rem;
	}
}

@media (min-width: 1100) {
	:root {
		font-size: 35.81%;
	}
}

@media (min-width: 1200) {
	:root {
		font-size: 39.06%;
	}
}

@media (min-width: 1280) {
	:root {
		font-size: 41.66%;
	}
}

@media (min-width: 1300) {
	:root {
		font-size: 42.32%;
	}
}

@media (min-width: 1400) {
	:root {
		font-size: 45.57%;
	}
}

@media (min-width: 1500) {
	:root {
		font-size: 48.83%;
	}
}

@media (min-width: 1600) {
	:root {
		font-size: 52.08%;
	}
}

@media (min-width: 1700) {
	:root {
		font-size: 55.34%;
	}
}

@media (min-width: 1800) {
	:root {
		font-size: 58.59%;
	}
}
@media (min-width: 1920) {
	:root {
		font-size: 62.5%;
	}

	.video {
		width: auto;
	}
}

@media (min-width: 1921) {
	body {
		background-color: var(--white-web-space);
	}

	main {
		background-color: white;
		margin-top: 8rem;
	}
}
